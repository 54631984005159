(function() {
    'use strict';

    angular
        .module('protectApp')
        .controller('FormulaireSignController', FormulaireSignController);

    FormulaireSignController.$inject = ['$uibModalInstance', '$scope', 'Formulaire', '$http'];

    function FormulaireSignController ($uibModalInstance, $scope, Formulaire, $http) {


        var vm = this;
        vm.formulaire = Formulaire.formulaire;
        vm.cancel = cancel;
        vm.sign = sign;
        vm.badUser = false;

        function cancel () {
            $uibModalInstance.dismiss('cancel');
        }

        function sign() {
			vm.isSaving = true;
            $http({
                method: 'POST',
                url: 'api/users/check-login',
                data: {username: vm.formulaire.data.login, password: vm.formulaire.data.password}
            }).then(function(response) {
                if(response.data) {
                    Formulaire.update(vm.formulaire.data, onSaveSuccess, onSaveError);
                } else {
                    vm.badUser = true;
                    vm.isSaving = false;
                }
            });
		}

		function onSaveSuccess (result) {
			$scope.$emit('protectApp:formulaireUpdate', result);
            Formulaire.formulaire.data = {};
			vm.isSaving = false;
            $uibModalInstance.close(result);
		}

		function onSaveError () {
			vm.isSaving = false;
		}
    }
})();
