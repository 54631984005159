(function() {
    'use strict';
    angular
        .module('protectApp')
        .factory('Formulaire', Formulaire);

    Formulaire.$inject = ['$resource', 'DateUtils', 'Principal', '$q' ];

    function Formulaire ($resource, DateUtils, Principal, $q) {
        var resourceUrl =  'api/formulaires/:id';

        var resource = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.saveDateTime = DateUtils.convertDateTimeFromServer(data.saveDateTime);
                        data.jdtc = DateUtils.convertLocalDateFromServer(data.jdtc);
                        data.prgdtc = DateUtils.convertLocalDateFromServer(data.prgdtc);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.jdtc = DateUtils.convertLocalDateToServer(copy.jdtc);
                    copy.prgdtc = DateUtils.convertLocalDateToServer(copy.prgdtc);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.jdtc = DateUtils.convertLocalDateToServer(copy.jdtc);
                    copy.prgdtc = DateUtils.convertLocalDateToServer(copy.prgdtc);
                    return angular.toJson(copy);
                }
            }
        });

        resource.formulaire = {
            data: {}
        };
        resource.editForm = {

        };
        resource.clear = function() {
            this.editForm.$setUntouched();
            this.editForm.$setPristine();
            this.editForm.$setValidity();
            $('.divLabel').removeClass('ng-invalid');
            angular.element('#div_multiple_choice').removeClass('ng-invalid');
        };

        resource.getFormulaireNumber = function() {
            var deffered = $q.defer();
            Principal.identity(true).then(function (identity) {
               var patientNumber = identity.lastPatientNumber + 1;
               var centerNumber = identity.centerNumber;
               var result = centerNumber +'_' + ('0000' + patientNumber).slice(-4);
               deffered.resolve(result);
            });

            return deffered.promise;
        };

        resource.canCreateFormulaire = function () {
          return Principal.canCreateFormulaire();
        };
        return resource;
    }
})();
