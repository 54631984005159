(function() {
    'use strict';

    angular
        .module('protectApp')
        .controller('FormulaireDialogController', FormulaireDialogController);

    FormulaireDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'Formulaire', '$rootScope', '$uibModal', '$location', '$anchorScroll', '$state'];

    function FormulaireDialogController ($timeout, $scope, $stateParams, entity, Formulaire, $rootScope, $uibModal, $location, $anchorScroll, $state) {
        var vm = this;

        vm.formulaire = Formulaire.formulaire;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.formulaire.data.jdtc = new Date();
        vm.formulaire.data.atcdOLD = {};
        vm.checkFormulaire = checkFormulaire;
        vm.isMultipleChoiceRequired = isMultipleChoiceRequired;
        vm.isThereErrors = false;
        vm.printErrorLogger = false;
        vm.canCreateFormulaire = true;

        var firstCM = true;
        $timeout(function () {
            Formulaire.editForm = $scope.editForm;
        }, 0);

        Formulaire.getFormulaireNumber().then(function (number) {
            vm.formulaireNumber = number;
        });

        Formulaire.canCreateFormulaire().then(function (result) {
           vm.canCreateFormulaire = result;
        });

        function isMultipleChoiceRequired() {
            var result = !vm.formulaire.data.rmeff && !vm.formulaire.data.rintol && !vm.formulaire.data.rprix && !vm.formulaire.data.rcomp
                && !vm.formulaire.data.rposo && !vm.formulaire.data.revol && !vm.formulaire.data.rpresc && !vm.formulaire.data.rcnsl
                && !vm.formulaire.data.roth;

            if(!Formulaire.editForm.$submitted && Formulaire.editForm.$pristine) {
                $('#div_multiple_choice').removeClass('ng-invalid');
                vm.printErrorLogger = false;
                return true;
            }
            if(Formulaire.editForm.$pristine && Formulaire.editForm.$submitted && result === true) {
                $('#div_multiple_choice').addClass('ng-invalid');
                return false;
            }
            if(result === false) {
                $('#div_multiple_choice').removeClass('ng-invalid');
                firstCM = false;
            } else if(!firstCM){
                $('#div_multiple_choice').addClass('ng-invalid');
            }
            return result && !firstCM;
        }

        $('input[type=radio]').click(function() {
            $(this).parents(".divLabel").removeClass('ng-invalid');
        });

        $scope.$watch('editForm.$error.required', function() {
           if($scope.editForm.$error.required && $scope.editForm.$error.required.length > 0) {
               vm.isThereErrors = true;
           } else {
               vm.isThereErrors = false;
           }
        });

        function checkFormulaire() {
            console.log(vm.formulaire.data.supec);
            console.log(vm.formulaire.data.dpris);
            if(!$scope.editForm.$error.required) {
                if((vm.formulaire.data.supec === 'false' && vm.formulaire.data.dpris === undefined)) {
                    $location.hash('divLogger');
                    $anchorScroll();
                    return false;
                }
                return true;
            }
            angular.forEach($scope.editForm.$error.required, function (item) {
                var i = 0;
                $('input[name="'+item.$name+ '"]').each(function() {
                    $(this).parents(".divLabel").addClass('ng-invalid');
                });
            });
            vm.printErrorLogger = true;
            $location.hash('divLogger');
            $anchorScroll();
            return false;
        }

        var checkIfNbComp2 = function() {
            return vm.formulaire.data.nbalmo === 'DEUX' || vm.formulaire.data.nbalmo === 'TROIS' || vm.formulaire.data.nbalmo === 'QUATRE' || vm.formulaire.data.nbalmo === 'PLUS_QUATRE';
        };

        var checkIfNbComp3 = function() {
            return vm.formulaire.data.nbalmo === 'TROIS' || vm.formulaire.data.nbalmo === 'QUATRE' || vm.formulaire.data.nbalmo === 'PLUS_QUATRE';
        };

        var checkIfNbComp4 = function() {
            return  vm.formulaire.data.nbalmo === 'QUATRE' || vm.formulaire.data.nbalmo === 'PLUS_QUATRE';
        };

        vm.checkIfNbComp2 = checkIfNbComp2;
        vm.checkIfNbComp3 = checkIfNbComp3;
        vm.checkIfNbComp4 = checkIfNbComp4;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            Formulaire.editForm = $scope.editForm;
            Formulaire.clear();
            vm.formulaire.data = {jdtc: new Date()};
            vm.isThereErrors = false;
            Formulaire.getFormulaireNumber().then(function (number) {
                vm.formulaireNumber = number;
            });
        }

        function save(){
            firstCM = false;
            if(checkFormulaire() === false) {
                return;
            }
            var scope = $rootScope.$new();
            $scope.formulaire = vm.formulaire;
            Formulaire.editForm = $scope.editForm;
            vm.isThereErrors = false;
            vm.printErrorLogger = false;
            $uibModal.open({
                templateUrl: 'app/entities/formulaire/formulaire-sign-dialog.html',
                controller: 'FormulaireSignController',
                controllerAs: 'vm',
                scope: scope
            }).result.then(function(result) {
                $state.go('formulaire.saved', {id : result.id});
            });}

        function onSaveSuccess (result) {
            $scope.$emit('protectApp:formulaireUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.checkClickATCD = function(bool) {
            if(vm.formulaire.data.atcdOLD == bool) {
                vm.formulaire.data.atcd = undefined;
            }
            vm.formulaire.data.atcdOLD = vm.formulaire.data.atcd;
        };

        vm.datePickerOpenStatus.saveDateTime = false;
        vm.datePickerOpenStatus.jdtc = false;
        vm.datePickerOpenStatus.prgdtc = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        console.log(vm.isThereErrors )
        console.log(vm.printErrorLogger)

    }
})();
