(function() {
    'use strict';

    angular
        .module('protectApp')
        .controller('FormulaireSavedController',FormulaireSavedController);

    FormulaireSavedController.$inject = ['$uibModalInstance', 'entity', 'Formulaire', '$scope'];

    function FormulaireSavedController($uibModalInstance, entity, Formulaire, $scope) {
        var vm = this;

        vm.formulaire = entity;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
            Formulaire.clear();
        }


    }
})();
