(function() {
    'use strict';

    angular
        .module('protectApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$http'];

    function NavbarController ($state, Auth, Principal, ProfileService, LoginService, $http) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        Principal.canCreateFormulaire().then(function (result) {
           vm.canCreateFormulaire = result;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.download = download;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function download(url){
        	return $http.get(url).success(function(data, status, headers //, config
        			) {
        		var filename = url.substring(url.lastIndexOf('/')+1);
        	     var anchor = angular.element('<a/>');
        	     var blob = new Blob([data], {type: headers('Content-Type')}),
                 newHref = window.URL.createObjectURL(blob);
        	     anchor.attr({
        	         href: newHref,
        	         target: '_blank',
        	         download: filename
        	     })[0].click();

        	  }).
        	  error(function() {
        	    // handle error
        	  });
        }
    }
})();
