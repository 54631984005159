(function() {
    'use strict';

    angular
        .module('protectApp')
        .filter('i18n', Translate);

    
    Translate.$inject = ['$translate'];
    
    function Translate($translate) {
        return translateFilter;

        function translateFilter (input, prefix) {
            if (prefix){
                input = prefix + input;
            }
            if (input !== null) {
                return $translate.instant(input);
            } else {return input;}
        }
    }
})();
