(function() {
    'use strict';

    angular
        .module('protectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('formulaire', {
                parent: 'entity',
                url: '/formulaire?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'protectApp.formulaire.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/formulaire/formulaires.html',
                        controller: 'FormulaireController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('formulaire');
                        $translatePartialLoader.addPart('oeil');
                        $translatePartialLoader.addPart('categorieMCLAS');
                        $translatePartialLoader.addPart('prisePCONT');
                        $translatePartialLoader.addPart('compAlim');
                        $translatePartialLoader.addPart('capsulesNBCAP');
                        $translatePartialLoader.addPart('produitNPROD');
                        $translatePartialLoader.addPart('capsulesNBCAP');
                        $translatePartialLoader.addPart('produitNPROD');
                        $translatePartialLoader.addPart('capsulesNBCAP');
                        $translatePartialLoader.addPart('produitNPROD');
                        $translatePartialLoader.addPart('capsulesNBCAP');
                        $translatePartialLoader.addPart('produitNPROD');
                        $translatePartialLoader.addPart('capsulesNBCAP');
                        $translatePartialLoader.addPart('produitNPROD');
                        $translatePartialLoader.addPart('sexe');
                        $translatePartialLoader.addPart('age');
                        $translatePartialLoader.addPart('origine');
                        $translatePartialLoader.addPart('fumeurTABA');
                        $translatePartialLoader.addPart('anomalieREFR');
                        $translatePartialLoader.addPart('couleurIRIS');
                        $translatePartialLoader.addPart('risqueOGDMLA');
                        $translatePartialLoader.addPart('risqueODDMLA');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('nbNBALMO');
                        return $translate.refresh();
                    }]
                }
            })
            .state('formulaire-detail', {
                parent: 'formulaire',
                url: '/formulaire/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'protectApp.formulaire.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/formulaire/formulaire-detail.html',
                        controller: 'FormulaireDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('formulaire');
                        $translatePartialLoader.addPart('oeil');
                        $translatePartialLoader.addPart('categorieMCLAS');
                        $translatePartialLoader.addPart('prisePCONT');
                        $translatePartialLoader.addPart('compAlim');
                        $translatePartialLoader.addPart('capsulesNBCAP');
                        $translatePartialLoader.addPart('produitNPROD');
                        $translatePartialLoader.addPart('capsulesNBCAP');
                        $translatePartialLoader.addPart('produitNPROD');
                        $translatePartialLoader.addPart('capsulesNBCAP');
                        $translatePartialLoader.addPart('produitNPROD');
                        $translatePartialLoader.addPart('capsulesNBCAP');
                        $translatePartialLoader.addPart('produitNPROD');
                        $translatePartialLoader.addPart('capsulesNBCAP');
                        $translatePartialLoader.addPart('produitNPROD');
                        $translatePartialLoader.addPart('sexe');
                        $translatePartialLoader.addPart('age');
                        $translatePartialLoader.addPart('origine');
                        $translatePartialLoader.addPart('fumeurTABA');
                        $translatePartialLoader.addPart('anomalieREFR');
                        $translatePartialLoader.addPart('couleurIRIS');
                        $translatePartialLoader.addPart('risqueOGDMLA');
                        $translatePartialLoader.addPart('risqueODDMLA');
                        $translatePartialLoader.addPart('nbNBALMO');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Formulaire', function($stateParams, Formulaire) {
                        return Formulaire.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'formulaire',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('formulaire-detail.edit', {
                parent: 'formulaire-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/formulaire/formulaire-dialog.html',
                        controller: 'FormulaireDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Formulaire', function(Formulaire) {
                                return Formulaire.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('formulaire-new', {
                parent: 'formulaire',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/formulaire/formulaire-dialog.html',
                        controller: 'FormulaireDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            centre: null,
                            patientNumber: null,
                            saveDateTime: null,
                            jdtc: null,
                            prgdtc: null,
                            oivt: null,
                            mclas: null,
                            calm: null,
                            ppris: null,
                            supec: null,
                            dpris: null,
                            pcont: null,
                            curmoi: null,
                            almvoc: null,
                            nbalmo: null,
                            nbcap: null,
                            capoth: null,
                            nprod: null,
                            prodoth: null,
                            cp1ad: null,
                            cp1af: null,
                            nbcap1: null,
                            capoth1: null,
                            nprod1: null,
                            prodoth1: null,
                            cp2ad: null,
                            cp2af: null,
                            nbcap2: null,
                            capoth2: null,
                            nprod2: null,
                            prodoth2: null,
                            cp3ad: null,
                            cp3af: null,
                            nbcap3: null,
                            capoth3: null,
                            prodoth3: null,
                            nprod3: null,
                            cp4ad: null,
                            cp4af: null,
                            nbcap4: null,
                            capoth4: null,
                            nprod4: null,
                            prodoth4: null,
                            rmeff: null,
                            rintol: null,
                            rprix: null,
                            rcomp: null,
                            rposo: null,
                            revol: null,
                            rpresc: null,
                            rcnsl: null,
                            roth: null,
                            rothco: null,
                            sexe: null,
                            age: null,
                            orign: null,
                            atcd: null,
                            poids: null,
                            taill: null,
                            taba: null,
                            hta: null,
                            idm: null,
                            ascl: null,
                            hcho: null,
                            cata: null,
                            refr: null,
                            iris: null,
                            ogdmla: null,
                            oddmla: null,
                            id: null
                        };
                    }
                }
            })
            .state('formulaire.edit', {
                parent: 'formulaire',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/formulaire/formulaire-dialog.html',
                        controller: 'FormulaireDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Formulaire', function(Formulaire) {
                                return Formulaire.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('formulaire', null, { reload: 'formulaire' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('formulaire.delete', {
                parent: 'formulaire',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/formulaire/formulaire-delete-dialog.html',
                        controller: 'FormulaireDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Formulaire', function(Formulaire) {
                                return Formulaire.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('formulaire', null, { reload: 'formulaire' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('formulaire.saved', {
                parent: 'formulaire-new',
                url: '/{id}/saved',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/formulaire/formulaire-saved-dialog.html',
                        controller: 'FormulaireSavedController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Formulaire', function(Formulaire) {
                                return Formulaire.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('formulaire-new', null, { reload: true });
                    }, function() {
                        $state.go('formulaire-new', null, { reload: true });
                    });
                }]
            })
            ;
    }

})();
